import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid, TextField, Button, Typography, Container, Box, Snackbar, CircularProgress, Alert } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";  
import { motion } from "framer-motion";  
import logo from '../../images/logo.png';

axios.defaults.baseURL = "https://kb.telepresenz.com";  

// Styled components
const FormContainer = styled('div')({
  maxWidth: "500px",
  margin: "auto",
  padding: "20px",
  backgroundColor: "#fff",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  marginTop: "50px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  '@media (max-width:600px)': {
    marginTop: "20px",
    padding: "15px",
  },
});

const LogoContainer = styled(Box)({
  textAlign: "center",
  marginBottom: "20px",
});

const LogoImage = styled('img')({
  width: "300px",
  height: "auto",
});

const Tagline = styled(Typography)({
  fontStyle: "italic",
  color: "#555",
  marginTop: "10px",
  fontSize: "1.2rem",
});

const FormTitle = styled(Typography)({
  textAlign: "center",
  fontWeight: "bold",
  marginBottom: "20px",
  color: "#333",
  '@media (max-width:600px)': {
    fontSize: "1.5rem",
  },
});

const InputField = styled(TextField)({
  width: "100%",
  marginBottom: "15px",
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: "#4CAF50",
    },
    '&:hover fieldset': {
      borderColor: "#45a049",
    },
    '&.Mui-focused fieldset': {
      borderColor: "#388E3C",
    },
  },
  '@media (max-width:600px)': {
    marginBottom: "12px",
  },
});

const SubmitButton = styled(Button)({
  width: "100%",
  backgroundColor: "#4CAF50",
  color: "#fff",
  '&:hover': {
    backgroundColor: "#45a049",
  },
  '@media (max-width:600px)': {
    padding: "12px",
  },
});

const ToggleForm = styled(Box)({
  textAlign: "center",
  marginTop: "10px",
  color: "#333",
});

const Footer = styled(Box)({
  marginTop: "20px",
  textAlign: "center",
  fontSize: "0.8rem",
  color: "#777",
});

const AuthForm = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);  // Add this state for tracking the user
  const navigate = useNavigate();  

  // Check if the user is in local storage
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setCurrentUser(storedUser);  // Set user state if found in local storage
      console.log('User found in local storage:', storedUser);
    } else {
      console.log('No user found in local storage');
    }
  }, []);  // Empty dependency array ensures this runs only once when the component mounts

  // Handle login
  const handleLogin = (e) => {
    e.preventDefault();
    localStorage.setItem('user', username);  
    setCurrentUser(username);  // Update the current user state
    navigate('/home', { state: { currentUser: username } });  // Pass currentUser in state
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const endpoint = isLogin ? "/api/auth/login" : "/api/auth/register";
    const data = isLogin ? { email, password } : { username, email, password };

    try {
      const response = await axios.post(endpoint, data);
      setLoading(false);
      setMessage(response.data.message || "Success!");
      setIsSuccess(true);
      setOpenSnack(true);

      if (isLogin) {
        localStorage.setItem('user', username);  // Store username for logged-in user
        setCurrentUser(username);  // Update currentUser after login
        navigate("/home"); 
      }

      if (!isLogin) {
        setUsername(""); 
        setEmail("");     
        setPassword("");  
      }
    } catch (error) {
      setLoading(false);
      const errorMsg = error.response?.data?.error || "Something went wrong!";
      setMessage(errorMsg);
      setIsSuccess(false);
      setOpenSnack(true);
    }
  };

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setMessage("");
  };

  return (
    <Container maxWidth="sm" sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
      <FormContainer>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <LogoContainer>
            <LogoImage src={logo} alt="Logo" />
            <Tagline><b>Knowledge Base</b></Tagline>
          </LogoContainer>
        </motion.div>

        <FormTitle variant="h4">{isLogin ? "Login" : "Register"}</FormTitle>

        <form onSubmit={handleSubmit}>
          {!isLogin && (
            <InputField
              label="Username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          )}

          <InputField
            label="Email"
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <InputField
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <SubmitButton type="submit" variant="contained" disabled={loading}>
            {loading ? <CircularProgress size={24} style={{ color: "#fff" }} /> : isLogin ? "Login" : "Register"}
          </SubmitButton>
        </form>

        <ToggleForm>
          {isLogin ? (
            <>
              Don't have an account?{" "}
              <Button onClick={toggleForm} style={{ textDecoration: "underline", color: "#4CAF50" }}>
                Create one
              </Button>
            </>
          ) : (
            <>
              Already have an account?{" "}
              <Button onClick={toggleForm} style={{ textDecoration: "underline", color: "#4CAF50" }}>
                Login here
              </Button>
            </>
          )}
        </ToggleForm>

        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={() => setOpenSnack(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={() => setOpenSnack(false)} severity={isSuccess ? "success" : "error"} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>

        <Footer>
          <Typography variant="body2">&copy; 2024 Telepresenz Inc. All rights reserved.</Typography>
          <Typography variant="body2">Version 1.0.0</Typography>
        </Footer>
      </FormContainer>
    </Container>
  );
};

export default AuthForm;
