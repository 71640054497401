import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Input,
  InputGroupText,
  InputGroup,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "./Knowledgebase.css";
import { FiLink } from "react-icons/fi";
import { CiFileOn } from "react-icons/ci";

const KnowledgebaseMain = ({ fileData = [] }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [titleDropdownOpen, setTitleDropdownOpen] = useState(false);
  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);
  const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
  console.log(fileData);

  const itemsPerPage = 8;

  const toggleTitleDropdown = () =>
    setTitleDropdownOpen((prevState) => !prevState);
  const toggleLocationDropdown = () =>
    setLocationDropdownOpen((prevState) => !prevState);
  const toggleCategoryDropdown = () =>
    setCategoryDropdownOpen((prevState) => !prevState);

  const uniqueTitles = [...new Set(fileData.map((file) => file.title))];
  const uniqueLocations = [...new Set(fileData.map((file) => file.location))];
  const uniqueCategories = [...new Set(fileData.map((file) => file.category))];

  const filteredFiles = fileData.filter((file) => {
    const matchesSearch =
      file.title?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      file.description?.toLowerCase()?.includes(searchTerm.toLowerCase());
    const matchesTitle = selectedTitle ? file.title === selectedTitle : true;
    const matchesLocation = selectedLocation
      ? file.location === selectedLocation
      : true;
    const matchesCategory = selectedCategory
      ? file.category === selectedCategory
      : true;
    return matchesSearch && matchesTitle && matchesLocation && matchesCategory;
  });

  const totalItems = filteredFiles.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filteredFiles.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPreview = (file) => {
    console.log("files", file?.media_files);

    const videoFile = file?.media_files?.find(
      (media) => media?.content_type?.toLowerCase() === "video"
    );

    if (videoFile) {
      return <video width="100%" src={`${videoFile.file_path}`} />;
    }
    const voiceNoteFile = file?.media_files?.find(
      (media) => media?.content_type?.toLowerCase() === "voice note"
    );

    if (voiceNoteFile) {
      return (
        <a
          href={voiceNoteFile.file_path}
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <CiFileOn size={110} />
        </a>
      );
    }
    return <p>Preview not found!</p>;
  };

  const getPaginationRange = () => {
    const maxPagesToShow = 10;
    const halfRange = 5;
    let startPage = Math.max(currentPage - halfRange, 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  };

  const handleCopyLink = (relativePath) => {
    const fullUrl = `${window.location.origin}${relativePath}`;
    navigator.clipboard.writeText(fullUrl).then(() => {
      alert("Link copied to clipboard!");
    });
  };

  return (
    <Container className="mt-2">
      <Row className="align-items-center">
        <Col md="6">
          <InputGroup className="mb-4">
            <InputGroupText>🔍</InputGroupText>
            <Input
              type="text"
              placeholder="Search files by name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </InputGroup>
        </Col>
        <Col md="2">
          <Dropdown
            isOpen={titleDropdownOpen}
            toggle={toggleTitleDropdown}
            className="mb-4"
          >
            <DropdownToggle caret>
              {selectedTitle.slice(0, 23) || "Filter by Title"}
            </DropdownToggle>
            <DropdownMenu style={{ maxHeight: "200px", overflowY: "auto" }}>
              <DropdownItem onClick={() => setSelectedTitle("")}>
                All Titles
              </DropdownItem>
              {uniqueTitles.map((title, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => setSelectedTitle(title)}
                >
                  {title}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </Col>
        <Col md="2">
          <Dropdown
            isOpen={locationDropdownOpen}
            toggle={toggleLocationDropdown}
            className="mb-4"
          >
            <DropdownToggle caret>
              {selectedLocation.slice(0, 23) || "Filter by Location"}
            </DropdownToggle>
            <DropdownMenu style={{ maxHeight: "200px", overflowY: "auto" }}>
              <DropdownItem onClick={() => setSelectedLocation("")}>
                All Locations
              </DropdownItem>
              {uniqueLocations.map((location, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => setSelectedLocation(location)}
                >
                  {location}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </Col>
        <Col md="2">
          <Dropdown
            isOpen={categoryDropdownOpen}
            toggle={toggleCategoryDropdown}
            className="mb-4"
          >
            <DropdownToggle caret>
              {selectedCategory || "Filter by Category"}
            </DropdownToggle>
            <DropdownMenu style={{ maxHeight: "200px", overflowY: "auto" }}>
              <DropdownItem onClick={() => setSelectedCategory("")}>
                All Categories
              </DropdownItem>
              {uniqueCategories.map((category, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row>

      <Row>
        {fileData?.length > 0 ? (
          currentItems?.length > 0 ? (
            currentItems?.map((file) => (
              <Col sm="3" key={file.knowledge_base_id} className="mb-4">
                <div>
                  <Card className="card">
                    {renderPreview(file)}
                    <CardBody className="card-body">
                      <CardTitle tag="h5">
                        <p
                          onClick={() =>
                            window.open(
                              `/details/${file.knowledge_base_id}`,
                              "_blank"
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {file.title.slice(0, 40)}...
                        </p>
                      </CardTitle>
                      <CardText>
                        <small className="text-muted">{file.date}</small>
                      </CardText>
                      <CardText>{file.description.slice(0, 60)}...</CardText>
                      <Row style={{ alignItems: "center" }}>
                        <Col md={8}>
                          <Button
                            color="primary"
                            onClick={() =>
                              window.open(
                                `/details/${file.knowledge_base_id}`,
                                "_blank"
                              )
                            }
                            style={{ textDecoration: "none", borderRadius: 0 }}
                          >
                            View Details
                          </Button>
                        </Col>
                        <Col>
                          <FiLink
                            size={25}
                            onClick={() =>
                              handleCopyLink(
                                `/details/${file.knowledge_base_id}`
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            ))
          ) : (
            <p className="text-center mt-4">No files found for your search.</p>
          )
        ) : (
          <p className="text-center mt-4">
            <Spinner>Loading...</Spinner>
          </p>
        )}
      </Row>

      {totalPages > 1 && (
        <Pagination
          style={{
            display: "flex",
            justifyContent: "right",
            marginTop: "-20px",
          }}
        >
          <PaginationItem disabled={currentPage === 1}>
            <PaginationLink first onClick={() => handlePageChange(1)} />
          </PaginationItem>
          <PaginationItem disabled={currentPage === 1}>
            <PaginationLink
              previous
              onClick={() => handlePageChange(currentPage - 1)}
            />
          </PaginationItem>
          {getPaginationRange().map((pageNumber) => (
            <PaginationItem
              active={currentPage === pageNumber}
              key={pageNumber}
            >
              <PaginationLink onClick={() => handlePageChange(pageNumber)}>
                {pageNumber}
              </PaginationLink>
            </PaginationItem>
          ))}
          <PaginationItem disabled={currentPage === totalPages}>
            <PaginationLink
              next
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </PaginationItem>
          <PaginationItem disabled={currentPage === totalPages}>
            <PaginationLink last onClick={() => handlePageChange(totalPages)} />
          </PaginationItem>
        </Pagination>
      )}
    </Container>
  );
};

export default KnowledgebaseMain;
