import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const KnowledgeBaseDetails = () => {
  const { id } = useParams(); // Get ID from the URL parameter
  const [knowledgeBaseData, setKnowledgeBaseData] = useState(null);
  const [transcriptionText, setTranscriptionText] = useState("");
  const [previewContent, setPreviewContent] = useState(null);
  const [activeMediaId, setActiveMediaId] = useState(null); // Track active previewed media ID
  const [highlightedMediaId, setHighlightedMediaId] = useState(null); // Track the highlighted row

  // Fetch the data based on the ID from the URL
  useEffect(() => {
    const url = `https://kb.telepresenz.com/api/knowledge_base/${id}`;
    console.log("Fetching from URL:", url);

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched data:", data);
        setKnowledgeBaseData(data);

        // Auto-preview the first media file if available
        if (data.media_files && data.media_files.length > 0) {
          handleMediaFileClick(data.media_files[0]);
          setActiveMediaId(data.media_files[0].media_id);
        }
        const vttFile = data.media_files.find((file) =>
          file.file_path.toLowerCase().endsWith(".vtt")
        );
        if (vttFile) {
          parseVttFile(vttFile.file_path);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]);

  // Function to fetch and parse VTT file
  const parseVttFile = async (filePath) => {
    try {
      const response = await fetch(filePath);
      const text = await response.text();
      const vttLines = text.split("\n");
      const transcription = vttLines
        .filter((line) => !line.startsWith("NOTE") && line.trim() !== "")
        .join("\n");
      setTranscriptionText(transcription); // Set the transcription text
    } catch (error) {
      console.error("Error parsing VTT file:", error);
    }
  };

  // Function to preview a file
  const handleMediaFileClick = (mediaFile) => {
    autoPreviewFile(mediaFile);
    setHighlightedMediaId(mediaFile.media_id); // Highlight the selected row

    if (mediaFile.file_path.toLowerCase().endsWith(".vtt")) {
      parseVttFile(mediaFile.file_path); // Populate transcription section
    }
  };

  const autoPreviewFile = (mediaFile) => {
    const fileExtension = mediaFile.file_path.split(".").pop().toLowerCase();
    //const fileType =
    // fileExtension === "jpg" ||
    // fileExtension === "jpeg" ||
    // fileExtension === "png" ||
    // fileExtension === "gif"
    //   ? "image"
    //   : fileExtension === "mp4" ||
    //     fileExtension === "mov" ||
    //     fileExtension === "avi"
    //   ? "video"
    //   : fileExtension === "mp3" ||
    //     fileExtension === "wav" ||
    //     fileExtension === "ogg"
    //   ? "audio"
    //   : fileExtension === "vtt"
    //   ? "vtt" // Handle VTT files
    //   : "document";
    const fileType =
      fileExtension === "jpg" ||
      fileExtension === "jpeg" ||
      fileExtension === "png" ||
      fileExtension === "gif"
        ? "image"
        : fileExtension === "mp4" ||
          fileExtension === "mov" ||
          fileExtension === "avi"
        ? "video"
        : fileExtension === "mp3" ||
          fileExtension === "wav" ||
          fileExtension === "ogg"
        ? "audio"
        : fileExtension === "vtt"
        ? "vtt" // Handle VTT files
        : fileExtension === "ppt" ||
          fileExtension === "pptx" ||
          fileExtension === "doc" ||
          fileExtension === "docx" ||
          fileExtension === "xls" ||
          fileExtension === "xlsx"
        ? "document" // Added support for ppt, doc, docx, xls, xlsx
        : "unknown"; // Default fallback type

    if (fileType === "vtt") {
      // VTT File - Do not preview; Parse and render transcription instead
      parseVttFile(mediaFile.file_path);
      setPreviewContent(
        <p>VTT file content is displayed in the transcription text area.</p>
      );
    } else {
      const preview =
        fileType === "image" ? (
          <img
            src={mediaFile.file_path}
            alt="Media Preview"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        ) : fileType === "video" ? (
          <video controls style={{ width: "100%" }}>
            <source src={mediaFile.file_path} type={`video/${fileExtension}`} />
            Your browser does not support the video tag.
          </video>
        ) : fileType === "audio" ? (
          <audio controls style={{ width: "100%" }}>
            <source src={mediaFile.file_path} type={`audio/${fileExtension}`} />
            Your browser does not support the audio element.
          </audio>
        ) : (
          <iframe
            //src={mediaFile.file_path}
            style={{ width: "100%", height: "500px" }}
            //title="Document Preview"
            src={`https://docs.google.com/gview?url=${mediaFile.file_path}&embedded=true`}
            title="Document Preview"
          ></iframe>
        );

      setPreviewContent(preview);
    }
  };

  // Ensure the knowledgeBaseData is loaded before rendering
  if (!knowledgeBaseData) {
    return <div>Loading...</div>;
  }

  const selectedItem = knowledgeBaseData;

  return (
    <div>
      {[
        {
          title: `Details of Knowledge Base ID: ${selectedItem.knowledge_base_id}`,
          content: (
            <table
              border="1"
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginTop: "20px",
                backgroundColor: "#fff",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <thead style={{ backgroundColor: "#00796b", color: "#00796b" }}>
                <tr>
                  <th>Knowledge Base ID</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>Category</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedItem.knowledge_base_id}</td>
                  <td>{selectedItem.title}</td>
                  <td>{selectedItem.description}</td>
                  <td>{selectedItem.status}</td>
                  <td>{selectedItem.category}</td>
                  <td>{selectedItem.location}</td>
                </tr>
              </tbody>
            </table>
          ),
        },
        {
          title: "Media Files Associated with this Knowledge Base",
          content: (
            <table
              border="1"
              style={{
                width: "100%",
                borderCollapse: "collapse",
                marginTop: "20px",
                backgroundColor: "#fff",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <thead style={{ backgroundColor: "#00796b", color: "#00796b" }}>
                <tr>
                  <th>Media ID</th>
                  <th>File Path</th>
                  <th>Content Type</th>
                  <th>Size</th>
                  <th>Media Description</th>
                  <th>Tags</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {selectedItem.media_files &&
                selectedItem.media_files.length > 0 ? (
                  selectedItem.media_files.map((mediaFile) => (
                    <tr
                      key={mediaFile.media_id}
                      style={{
                        backgroundColor:
                          mediaFile.media_id === highlightedMediaId
                            ? "#dff0d8"
                            : "transparent",
                      }}
                    >
                      <td>{mediaFile.media_id}</td>
                      <td>{mediaFile.file_path}</td>
                      <td>{mediaFile.content_type}</td>
                      <td>{mediaFile.size}</td>
                      <td>{mediaFile.media_description}</td>
                      <td>
                        {/* Render tags as comma-separated */}
                        {Array.isArray(mediaFile.tags)
                          ? mediaFile.tags.join(", ") // Join tags if it's an array
                          : mediaFile.tags}
                      </td>
                      <td>
                        <button
                          onClick={() => handleMediaFileClick(mediaFile)}
                          style={{
                            backgroundColor: "#00796b",
                            color: "#fff",
                            border: "none",
                            padding: "5px 10px",
                            borderRadius: "5px",
                          }}
                        >
                          Preview
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">No media files available.</td>
                  </tr>
                )}
              </tbody>
            </table>
          ),
        },
        {
          title: "File Preview, Transcription, and AI Summary",
          content: (
            <div style={{ display: "flex", marginTop: "20px" }}>
              <div style={{ width: "50%", paddingRight: "20px" }}>
                <h4 style={{ color: "#00796b" }}>File Preview</h4>
                {previewContent && (
                  <div
                    style={{
                      marginTop: "20px",
                      border: "1px solid #ddd",
                      padding: "10px",
                    }}
                  >
                    {previewContent}
                  </div>
                )}
              </div>
              <div style={{ width: "50%" }}>
                <h4 style={{ color: "#00796b" }}>Transcription Text</h4>
                <textarea
                  value={transcriptionText}
                  onChange={(e) => setTranscriptionText(e.target.value)}
                  style={{ width: "100%", height: "545px" }}
                />
              </div>
            </div>
          ),
        },
      ].map(({ title, content }, idx) => (
        <div
          key={idx}
          style={{
            border: "1px solid #ddd",
            borderRadius: "5px",
            marginBottom: "20px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{
              padding: "10px 15px",
              cursor: "pointer",
              backgroundColor: "#00796b",
              color: "#fff",
              fontWeight: "bold",
              borderRadius: "5px 5px 0 0",
            }}
            onClick={() => {
              const contentDiv = document.getElementById(`content-${idx}`);
              contentDiv.style.display =
                contentDiv.style.display === "none" ? "block" : "none";
            }}
          >
            {title}
          </div>
          <div
            id={`content-${idx}`}
            style={{ padding: "15px", display: "block" }}
          >
            {content}
          </div>
        </div>
      ))}
    </div>
  );
};

export default KnowledgeBaseDetails;
