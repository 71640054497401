import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Table,
  Container,
  Toast,
} from "react-bootstrap";
import AWS from "aws-sdk";
import { Modal } from "react-bootstrap"; // Import Modal from react-bootstrap

const AddKnowledgeBase = () => {
  const [knowledgeBaseId, setKnowledgeBaseId] = useState(1);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("Created");
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const [mediaFiles, setMediaFiles] = useState([]);
  const [selectedContentType, setSelectedContentType] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [transcriptionFile, setTranscriptionFile] = useState(null);
  const [tagsInput, setTagsInput] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null); // Create a ref

  // AWS S3 configuration
  AWS.config.update({
    accessKeyId: "AKIAQFC27JCSYTI2UK5J",
    secretAccessKey: "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",
    region: "us-east-1",
  });

  const s3 = new AWS.S3();

  // Load data from local storage when the component mounts
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("knowledgeBaseData"));
    if (storedData) {
      setKnowledgeBaseId(storedData.knowledgeBaseId);
      setTitle(storedData.title);
      setDescription(storedData.description);
      setStatus(storedData.status);
      setCategory(storedData.category);
      setLocation(storedData.location);
      setMediaFiles(storedData.mediaFiles);
      setSelectedContentType(storedData.selectedContentType);
      setSelectedFiles(storedData.selectedFiles);
      setTranscriptionFile(storedData.transcriptionFile);
      setTagsInput(storedData.tagsInput);
      setIsSubmitted(storedData.isSubmitted);
      setShowToast(storedData.showToast);
    }
  }, []);

  // Save data to local storage whenever it changes
  useEffect(() => {
    const dataToStore = {
      knowledgeBaseId,
      title,
      description,
      status,
      category,
      location,
      mediaFiles,
      selectedContentType,
      selectedFiles,
      transcriptionFile,
      tagsInput,
      isSubmitted,
      showToast,
    };
    localStorage.setItem("knowledgeBaseData", JSON.stringify(dataToStore));
  }, [
    knowledgeBaseId,
    title,
    description,
    status,
    category,
    location,
    mediaFiles,
    selectedContentType,
    selectedFiles,
    transcriptionFile,
    tagsInput,
    isSubmitted,
    showToast,
  ]);

  const handleContentTypeChange = (event) => {
    setSelectedContentType(event.target.value);
    setSelectedFiles([]);
    setTranscriptionFile(null);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    setFiles(event.target.files);
  };

  const handleTranscriptionFileChange = (event) => {
    setTranscriptionFile(event.target.files[0]);
  };

  const handleAddFiles = () => {
    const updatedFiles = selectedFiles.map((file) => ({
      file,
      contentType: selectedContentType,
      transcriptionFile:
        selectedContentType === "Video" ? transcriptionFile : null,
      description: "",
      tags: [],
      size: file.size,
    }));
    setMediaFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
    setSelectedFiles([]);
    setTranscriptionFile(null);
  };

  const handleFileDescriptionChange = (index, event) => {
    const updatedFiles = [...mediaFiles];
    updatedFiles[index].description = event.target.value;
    setMediaFiles(updatedFiles);
  };

  const handleTagsInputChange = (event) => {
    setTagsInput(event.target.value);
  };

  // const handleAddTags = (index) => {
  //   const tagsArray = tagsInput
  //     .split(",")
  //     .map((tag) => tag.trim())
  //     .filter((tag) => tag);
  //   if (tagsArray.length > 0) {
  //     const updatedFiles = [...mediaFiles];
  //     updatedFiles[index].tags = [...updatedFiles[index].tags, ...tagsArray];
  //     setMediaFiles(updatedFiles);
  //     setTagsInput(""); // Clear the input field
  //   }
  // };
  const handleAddTags = (index) => {
    const updatedFiles = [...mediaFiles];
    if (updatedFiles[index].tagInput.trim() !== "") {
      updatedFiles[index].tags.push(updatedFiles[index].tagInput.trim());
      updatedFiles[index].tagInput = ""; // Clear input after adding
      setMediaFiles(updatedFiles);
    }
  };

  const handleDeleteFile = (index) => {
    const updatedFiles = [...mediaFiles];
    updatedFiles.splice(index, 1);
    setMediaFiles(updatedFiles);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingMessage("Uploading files to server...");
    setShowLoadingPopup(true); // Show the loading popup
    // setLoadingMessage('Uploading files to server...');
    // Get the current date and time in YYYY-MM-DD_HH-MM-SS format
    const dateTimeStamp = new Date()
      .toISOString()
      .replace(/[-:T]/g, "")
      .split(".")[0];

    const folderName = `KB-${knowledgeBaseId}-${location}-${title}-${dateTimeStamp}`;

    const uploadPromises = mediaFiles.map(async (item) => {
      const fileBlob =
        item.file instanceof Blob ? item.file : new Blob([item.file]);

      const fileKey = `${folderName}/${item.file.name}`;
      const params = {
        Bucket: "kbase-repo/dataupload/KnowledgeBase",
        Key: fileKey,
        Body: fileBlob,
      };
      console.log("Uploading to S3:", params);
      await s3.upload(params).promise();

      // Clear the file input control
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // This clears the file input
      }

      let transcriptionFileKey = null;

      if (item.transcriptionFile) {
        const transcriptionFileBlob =
          item.transcriptionFile instanceof Blob
            ? item.transcriptionFile
            : new Blob([item.transcriptionFile]);

        transcriptionFileKey = `${folderName}/${item.transcriptionFile.name}`;
        const transcriptionParams = {
          Bucket: "kbase-repo/dataupload/KnowledgeBase",
          Key: transcriptionFileKey,
          Body: transcriptionFileBlob,
        };
        console.log("Uploading transcription to S3:", transcriptionParams);
        await s3.upload(transcriptionParams).promise();
      }
      // Generate the complete URL for each uploaded file
      const fileUrl = `https://kbase-repo.s3.amazonaws.com/dataupload/KnowledgeBase/${fileKey}`;
      const transcriptionFileUrl = transcriptionFileKey
        ? `https://kbase-repo.s3.amazonaws.com/dataupload/KnowledgeBase/${transcriptionFileKey}`
        : null;

      // return {
      //   ...item,
      //   filePath: fileKey,
      //   transcriptionFilePath: transcriptionFileKey,
      // };
      return {
        ...item,
        filePath: fileKey,
        transcriptionFilePath: transcriptionFileKey,
        fileUrl: fileUrl, // Adding the AWS URL for the file
        transcriptionFileUrl: transcriptionFileUrl, // Adding the AWS URL for the transcription file
      };
    });

    const uploadedFiles = await Promise.all(uploadPromises);
    console.log("Uploaded Files:", uploadedFiles);
    console.log("Pre Folder Name:", folderName);

    // Update loading message after files are uploaded
    setLoadingMessage("Files uploaded. Updating data to database...");
    // Update the data in the database

    // Prepare data for API
    const postData = {
      title,
      description,
      status,
      category,
      location,
      folderName,
      mediaFiles: uploadedFiles.map((file) => ({
        filePath: file.fileUrl,
        contentType: file.contentType,
        description: file.description,
        tags: file.tags,
        size: file.size,
        transcriptionFilePath: file.transcriptionFileUrl || null,
      })),
    };

    try {
      console.log("Sending request to API...");
      const response = await fetch(
        "https://kb.telepresenz.com/api/saveKnowledgeBaseData",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      );
      console.log("API response:", response);
      if (response.ok) {
        const result = await response.json();
        console.log("Response from API:", result);
        setShowToast(true);
        setIsSubmitted(true);
        setShowLoadingPopup(false); // Hide the loading popup after successful data save
        console.log("Folder Name:", folderName);
        console.log("Post Data:", postData);

        // Reset all fields after successful submission and toast message
        setKnowledgeBaseId(1);
        setTitle("");
        setDescription("");
        setStatus("Created");
        setCategory("");
        setLocation("");
        setMediaFiles([]);
        setFiles([]); // Reset state if needed
        setSelectedContentType("");
        setSelectedFiles([]);
        setTranscriptionFile(null);
        setTagsInput("");

        setFiles([]); // Clear selected files state
        setSelectedFiles([]); // Clear selected files state
        setTranscriptionFile(null); // Clear transcription file
        setTagsInput(""); // Clear tags input
        setTitle(""); // Clear title
        setDescription(""); // Clear description
        // Ensure file input is cleared after submission
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // Clear file input
        }
      } else {
        console.error("API response error:", response.statusText);
        alert("Failed to save data.");
        setShowLoadingPopup(false); // Hide the loading popup after successful data save
      }
    } catch (error) {
      // console.error('Error during fetch call:', error);
      alert("An error occurred. Please try again.");
      setShowLoadingPopup(false); // Hide the loading popup after successful data save
    }
  };

  const handleAddNewRecord = () => {
    setKnowledgeBaseId(knowledgeBaseId + 1);
    setTitle("");
    setDescription("");
    setStatus("Created");
    setCategory("");
    setLocation("");
    setMediaFiles([]);
    setSelectedContentType("");
    setSelectedFiles([]);
    setTranscriptionFile(null);
    setIsSubmitted(false);
    setShowToast(false);
    // Clear file input
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // This clears the file input
    }
  };

  const handleTagInputChange = (index, value) => {
    const updatedFiles = [...mediaFiles];
    updatedFiles[index].tagInput = value;
    setMediaFiles(updatedFiles);
  };

  return (
    <Container fluid className="mt-5">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3 className="text-primary font-weight-bold">
          Create Knowledge Base Record
        </h3>
        {isSubmitted && (
          <Button
            variant="primary"
            onClick={handleAddNewRecord}
            className="shadow-sm"
          >
            Add New Record
          </Button>
        )}
      </div>

      {/* Loading Popup Modal */}
      <Modal
        show={showLoadingPopup}
        onHide={() => setShowLoadingPopup(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Loading...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{loadingMessage}</p>
        </Modal.Body>
      </Modal>

      <Form>
        <fieldset disabled={isSubmitted}>
          <div className="border rounded p-4 shadow-lg bg-light mb-4">
            <Row className="mb-4">
              <Col sm={3}>
                <Form.Label>Knowledge Base ID</Form.Label>
              </Col>
              <Col sm={9}>
                <Form.Control type="text" value={knowledgeBaseId} readOnly />
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={3}>
                <Form.Label>Status</Form.Label>
              </Col>
              <Col sm={9}>
                <Form.Control
                  as="select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  className="shadow-sm"
                >
                  <option>Created</option>
                  <option>Updated</option>
                  <option>Completed</option>
                </Form.Control>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={3}>
                <Form.Label>Title</Form.Label>
              </Col>
              <Col sm={9}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="shadow-sm"
                />
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={3}>
                <Form.Label>Description</Form.Label>
              </Col>
              <Col sm={9}>
                <Form.Control
                  as="textarea"
                  rows={5}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="shadow-sm"
                />
              </Col>
            </Row>
          </div>

          <div className="border rounded p-4 shadow-lg bg-light mb-4">
            <h5 className="font-weight-bold text-secondary mb-4">
              Content Type & Categorization
            </h5>
            <Row className="mb-4">
              <Col sm={3}>
                <Form.Label>Category</Form.Label>
              </Col>
              <Col sm={9}>
                <Form.Control
                  as="select"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  className="shadow-sm"
                >
                  <option>Select Category</option>
                  <option>SOPs</option>
                  <option>Workflows</option>
                  <option>Incidents</option>
                  <option>Manuals</option>
                  <option>Knowledge</option>
                </Form.Control>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={3}>
                <Form.Label>Location</Form.Label>
              </Col>
              <Col sm={9}>
                <Form.Control
                  as="select"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  className="shadow-sm"
                >
                  <option>Select Location</option>
                  <option>DTP AV Williams</option>
                  <option>DTP Bio Science</option>
                  <option>CEP</option>
                  <option>DTP4</option>
                  <option>B046 - DTP Marie Mount</option>
                  <option>B224 - DTP Atlantic</option>
                  <option>B250 - DTP Lennart Town</option>
                  <option>B068 - DTO Eppley Rec Center</option>
                  <option>B425 - DTP Prince Frederick</option>
                  <option>B073- DTP HJ Patterson</option>
                  <option>B226 - DTP Edward Saint John</option>
                  <option>B392 - DTP 3</option>
                  <option>B607 - DTP 2</option>
                  <option>B419 - DTP Oakland</option>
                  <option>B141 - DTP Tawes Hall</option>
                  <option>B416 - DTP 5</option>
                  <option>B143 - DTP Benjamin Hall</option>
                  <option>B019 - DTP 1</option>
                </Form.Control>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={3}>
                <Form.Label>Content Type</Form.Label>
              </Col>
              <Col sm={9}>
                <Form.Control
                  as="select"
                  value={selectedContentType}
                  onChange={handleContentTypeChange}
                  className="shadow-sm"
                >
                  <option value="">Select Content Type</option>
                  <option>Image</option>
                  <option>Video</option>
                  <option>Audio</option>
                  <option>Voice Note</option>
                  <option>Document</option>
                  <option>PDF</option>
                </Form.Control>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={3}>
                <Form.Label>Upload Files</Form.Label>
              </Col>
              <Col sm={9}>
                <Form.Control
                  type="file"
                  multiple
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  className="shadow-sm"
                />
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={3}>
                <Button
                  variant="primary"
                  onClick={handleAddFiles}
                  disabled={!selectedFiles.length || isSubmitted}
                  className="shadow-sm"
                >
                  Add Files
                </Button>
              </Col>
            </Row>
          </div>
          <div className="border rounded p-4 shadow-lg bg-light mb-4">
            <h5 className="fw-bold text-secondary mb-4">Media Files</h5>

            <Table
              striped
              bordered
              hover
              responsive
              className="table-hover align-middle"
            >
              <thead className="table-secondary">
                <tr>
                  <th style={{ width: "5%" }} className="text-center">
                    #
                  </th>
                  <th style={{ width: "25%" }}>File Info</th>
                  <th style={{ width: "30%" }}>Description</th>
                  <th style={{ width: "25%" }}>Tags</th>
                  <th style={{ width: "15%" }} className="text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {mediaFiles.map((file, index) => (
                  <tr key={index}>
                    {/* Index */}
                    <td className="text-center">{index + 1}</td>

                    {/* File Info */}
                    <td>
                      <div className="d-flex align-items-center">
                        {/* File Icon */}
                        <div className="me-2">
                          {file.contentType.startsWith("image") ? (
                            <img
                              src={URL.createObjectURL(file.file)}
                              alt={file.file.name}
                              width="40"
                              height="40"
                              className="rounded shadow-sm"
                            />
                          ) : file.contentType.startsWith("video") ? (
                            <i className="fas fa-video fa-lg text-primary"></i>
                          ) : file.contentType.startsWith("audio") ? (
                            <i className="fas fa-headphones fa-lg text-success"></i>
                          ) : (
                            <i className="fas fa-file-alt fa-lg text-info"></i>
                          )}
                        </div>

                        {/* File Details */}
                        <div>
                          <strong
                            className="d-block text-truncate"
                            style={{ maxWidth: "150px" }}
                          >
                            {file.file.name}
                          </strong>
                          <small className="text-muted">
                            {file.contentType} |{" "}
                            {(file.size / (1024 * 1024)).toFixed(2)} MB
                          </small>
                        </div>
                      </div>
                    </td>

                    {/* Description */}
                    <td>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        value={file.description}
                        onChange={(e) => handleFileDescriptionChange(index, e)}
                        className="shadow-sm"
                        placeholder="Enter file description here"
                      />
                    </td>

                    {/* Tags */}
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="Enter tags"
                        value={file.tagInput || ""}
                        onChange={(e) =>
                          handleTagInputChange(index, e.target.value)
                        }
                        className="shadow-sm mb-2"
                      />
                      <Button
                        variant="outline-primary"
                        onClick={() => handleAddTags(index)}
                        size="sm"
                        className="d-block w-100"
                      >
                        Add Tag
                      </Button>
                      <div className="mt-2">
                        {file.tags.length > 0 ? (
                          file.tags.map((tag, i) => (
                            <span
                              key={i}
                              className="badge bg-secondary me-1 mb-1"
                            >
                              {tag}
                            </span>
                          ))
                        ) : (
                          <small className="text-muted">No tags</small>
                        )}
                      </div>
                    </td>

                    {/* Actions */}
                    <td className="text-center">
                      <Button
                        variant="outline-danger"
                        onClick={() => handleDeleteFile(index)}
                        size="sm"
                        className="shadow-sm"
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </fieldset>

        <div className="d-flex justify-content-end">
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={!mediaFiles.length || isSubmitted}
            className="shadow-lg px-4"
          >
            Submit
          </Button>
        </div>
      </Form>

      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        style={{
          position: "fixed",
          bottom: 20,
          right: 20,
          zIndex: 1,
        }}
      >
        <Toast.Header>
          <strong className="me-auto">Success</strong>
        </Toast.Header>
        <Toast.Body>Knowledge Base Record submitted successfully!</Toast.Body>
      </Toast>
    </Container>
  );
};

export default AddKnowledgeBase;
