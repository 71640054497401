import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import "./Knowledgebase.css";
import { useEffect, useState } from "react";
import {
  FaFilePdf,
  FaFileWord,
  FaFilePowerpoint,
  FaFileExcel,
  FaFileImage,
  FaFileVideo,
  FaFile,
  FaClipboard,
  FaFileAudio,
  FaHeadphones,
} from "react-icons/fa";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const KnowledgeDetail = ({ fileData }) => {
  const [transcription, setTranscription] = useState(
    <Spinner>Loading...</Spinner>
  );
  const [modal, setModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const { id } = useParams();
  const file = fileData.find((f) => f.knowledge_base_id === parseInt(id, 10));
  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    if (!file) return;

    const voiceNote = file.media_files.find(
      (media) =>
        media.content_type?.toLowerCase() === "voice note" &&
        media.file_path.endsWith(".vtt")
    );

    if (voiceNote) {
      fetch(voiceNote.file_path)
        .then((response) => response.text())
        .then((data) => parseVTT(data))
        .catch(() => setTranscription("Failed to load transcription."));
    } else {
      setTranscription("No transcription available.");
    }
  }, [file]);

  const parseVTT = (vttContent) => {
    const cleanedContent = vttContent.replace(/^WEBVTT\s*/, "");

    const lines = cleanedContent.split("\n");

    let formattedTranscription = "";
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].match(/^\d+:\d+:\d+\.\d+/)) {
        const timecode = lines[i];
        if (i + 1 < lines.length && lines[i + 1].trim()) {
          const text = lines[i + 1];
          formattedTranscription += `<b>${timecode}</b>\n${text}\n`;
        }
      }
    }

    setTranscription(formattedTranscription.trim());
  };

  // const renderFileItem = (item) => {
  //   if (item?.file_path) {
  //     const fileName = item.file_path.split("/").pop();
  //     const fileExtension = fileName.split(".").pop().toLowerCase();

  //     // Define icons based on file types
  //     const fileIcons = {
  //       pdf: <FaFilePdf style={{ color: "#d9534f" }} />,
  //       doc: <FaFileWord style={{ color: "#0275d8" }} />,
  //       docx: <FaFileWord style={{ color: "#0275d8" }} />,
  //       ppt: <FaFilePowerpoint style={{ color: "#f0ad4e" }} />,
  //       pptx: <FaFilePowerpoint style={{ color: "#f0ad4e" }} />,
  //       xls: <FaFileExcel style={{ color: "#5cb85c" }} />,
  //       xlsx: <FaFileExcel style={{ color: "#5cb85c" }} />,
  //       png: <FaFileImage style={{ color: "#5bc0de" }} />,
  //       jpg: <FaFileImage style={{ color: "#5bc0de" }} />,
  //       tiff: <FaFileImage style={{ color: "#5bc0de" }} />,
  //       mp4: <FaFileVideo style={{ color: "#ff6347" }} />,
  //     };

  //     const fileIcon = fileIcons[fileExtension] || <FaFile />;

  //     if (fileIcons[fileExtension]) {
  //       const truncatedName =
  //         fileName.length > 50
  //           ? `${fileName.substring(0, 50)}...${fileExtension}`
  //           : fileName;

  //       return (
  //         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
  //           {fileIcon}
  //           <div
  //             style={{
  //               cursor: "pointer",
  //               textDecoration: "none",
  //               color: "blue",
  //             }}
  //             onClick={() => {
  //               setSelectedFile(item);
  //               toggleModal();
  //             }}
  //           >
  //             {truncatedName}
  //           </div>
  //         </div>
  //       );
  //     }
  //   }
  //   return null;
  // };

  // Downloading version const renderFileItem = (item) => {
  //   if (item?.file_path) {
  //     const fileName = item.file_path.split("/").pop();
  //     const fileExtension = fileName.split(".").pop().toLowerCase();

  //     // Define icons based on file types
  //     const fileIcons = {
  //       pdf: <FaFilePdf style={{ color: "#d9534f" }} />,
  //       doc: <FaFileWord style={{ color: "#0275d8" }} />,
  //       docx: <FaFileWord style={{ color: "#0275d8" }} />,
  //       ppt: <FaFilePowerpoint style={{ color: "#f0ad4e" }} />,
  //       pptx: <FaFilePowerpoint style={{ color: "#f0ad4e" }} />,
  //       xls: <FaFileExcel style={{ color: "#5cb85c" }} />,
  //       xlsx: <FaFileExcel style={{ color: "#5cb85c" }} />,
  //       png: <FaFileImage style={{ color: "#5bc0de" }} />,
  //       jpg: <FaFileImage style={{ color: "#5bc0de" }} />,
  //       tiff: <FaFileImage style={{ color: "#5bc0de" }} />,
  //       mp4: <FaFileVideo style={{ color: "#ff6347" }} />,
  //     };

  //     const fileIcon = fileIcons[fileExtension] || <FaFile />;

  //     if (fileIcons[fileExtension]) {
  //       const truncatedName =
  //         fileName.length > 50
  //           ? `${fileName.substring(0, 50)}...${fileExtension}`
  //           : fileName;

  //       return (
  //         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
  //           {fileIcon}
  //           <div
  //             style={{
  //               cursor: "pointer",
  //               textDecoration: "none",
  //               color: "blue",
  //             }}
  //             onClick={() => {
  //               // Open file in a new tab
  //               window.open(item.file_path, "_blank");
  //             }}
  //           >
  //             {truncatedName}
  //           </div>
  //         </div>
  //       );
  //     }
  //   }
  //   return null;
  // };

  // except pdf allw orking const renderFileItem = (item) => {
  //   if (item?.file_path) {
  //     const fileName = item.file_path.split("/").pop();
  //     const fileExtension = fileName.split(".").pop().toLowerCase();

  //     // Define icons based on file types
  //     const fileIcons = {
  //       pdf: <FaFilePdf style={{ color: "#d9534f" }} />,
  //       doc: <FaFileWord style={{ color: "#0275d8" }} />,
  //       docx: <FaFileWord style={{ color: "#0275d8" }} />,
  //       ppt: <FaFilePowerpoint style={{ color: "#f0ad4e" }} />,
  //       pptx: <FaFilePowerpoint style={{ color: "#f0ad4e" }} />,
  //       xls: <FaFileExcel style={{ color: "#5cb85c" }} />,
  //       xlsx: <FaFileExcel style={{ color: "#5cb85c" }} />,
  //       png: <FaFileImage style={{ color: "#5bc0de" }} />,
  //       jpg: <FaFileImage style={{ color: "#5bc0de" }} />,
  //       tiff: <FaFileImage style={{ color: "#5bc0de" }} />,
  //       mp4: <FaFileVideo style={{ color: "#ff6347" }} />,
  //     };

  //     const fileIcon = fileIcons[fileExtension] || <FaFile />;

  //     const openPreview = () => {
  //       setSelectedFile(item); // Update the selected file for preview
  //       toggleModal(); // Open the modal for preview
  //     };

  //     if (fileIcons[fileExtension]) {
  //       const truncatedName =
  //         fileName.length > 50
  //           ? `${fileName.substring(0, 50)}...${fileExtension}`
  //           : fileName;

  //       return (
  //         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
  //           {fileIcon}
  //           <div
  //             style={{
  //               cursor: "pointer",
  //               textDecoration: "none",
  //               color: "blue",
  //             }}
  //             onClick={openPreview} // Trigger preview on click
  //           >
  //             {truncatedName}
  //           </div>
  //         </div>
  //       );
  //     }
  //   }
  //   return null;
  // };
  // const renderFileItem = (item) => {
  //   if (item?.file_path) {
  //     const fileName = item.file_path.split("/").pop();
  //     const fileExtension = fileName.split(".").pop().toLowerCase();

  //     // Define icons based on file types
  //     const fileIcons = {
  //       pdf: <FaFilePdf style={{ color: "#d9534f" }} />,
  //       doc: <FaFileWord style={{ color: "#0275d8" }} />,
  //       docx: <FaFileWord style={{ color: "#0275d8" }} />,
  //       ppt: <FaFilePowerpoint style={{ color: "#f0ad4e" }} />,
  //       pptx: <FaFilePowerpoint style={{ color: "#f0ad4e" }} />,
  //       xls: <FaFileExcel style={{ color: "#5cb85c" }} />,
  //       xlsx: <FaFileExcel style={{ color: "#5cb85c" }} />,
  //       png: <FaFileImage style={{ color: "#5bc0de" }} />,
  //       jpg: <FaFileImage style={{ color: "#5bc0de" }} />,
  //       tiff: <FaFileImage style={{ color: "#5bc0de" }} />,
  //       mp4: <FaFileVideo style={{ color: "#ff6347" }} />,
  //     };

  //     const fileIcon = fileIcons[fileExtension] || <FaFile />;

  //     const handlePreview = () => {
  //       if (fileExtension === "pdf") {
  //         // Open PDF in a new tab
  //         window.open(item.file_path, "_blank");
  //       } else {
  //         setSelectedFile(item); // For non-PDF preview, if needed
  //         toggleModal();
  //       }
  //     };

  //     const handleCopyLink = () => {
  //       navigator.clipboard.writeText(item.file_path).then(() => {
  //         alert("File path copied to clipboard!");
  //       });
  //     };

  //     const truncatedName =
  //       fileName.length > 50
  //         ? `${fileName.substring(0, 50)}...${fileExtension}`
  //         : fileName;

  //     return (
  //       <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
  //         {fileIcon}
  //         <div
  //           style={{
  //             cursor: "pointer",
  //             textDecoration: "none",
  //             color: "blue",
  //           }}
  //           onClick={handlePreview} // Open preview
  //         >
  //           {truncatedName}
  //         </div>
  //         {/* Copy Link Button */}
  //         <Button
  //           color="secondary"
  //           size="sm"
  //           onClick={handleCopyLink}
  //           title="Copy file path"
  //         >
  //           Copy Link
  //         </Button>
  //       </div>
  //     );
  //   }
  //   return null;
  // };
  // const renderFileItem = (item) => {
  //   if (item?.file_path) {
  //     const fileName = item.file_path.split("/").pop();
  //     const fileExtension = fileName.split(".").pop().toLowerCase();

  //     // Define icons based on file types
  //     const fileIcons = {
  //       pdf: <FaFilePdf style={{ color: "#d9534f" }} />,
  //       doc: <FaFileWord style={{ color: "#0275d8" }} />,
  //       docx: <FaFileWord style={{ color: "#0275d8" }} />,
  //       ppt: <FaFilePowerpoint style={{ color: "#f0ad4e" }} />,
  //       pptx: <FaFilePowerpoint style={{ color: "#f0ad4e" }} />,
  //       xls: <FaFileExcel style={{ color: "#5cb85c" }} />,
  //       xlsx: <FaFileExcel style={{ color: "#5cb85c" }} />,
  //       png: <FaFileImage style={{ color: "#5bc0de" }} />,
  //       jpg: <FaFileImage style={{ color: "#5bc0de" }} />,
  //       tiff: <FaFileImage style={{ color: "#5bc0de" }} />,
  //       mp4: <FaFileVideo style={{ color: "#ff6347" }} />,
  //     };

  //     const fileIcon = fileIcons[fileExtension] || <FaFile />;

  //     const truncatedName =
  //       fileName.length > 50
  //         ? `${fileName.substring(0, 50)}...${fileExtension}`
  //         : fileName;

  //     const handleCopyLink = () => {
  //       navigator.clipboard.writeText(item.file_path);
  //       alert("Link copied to clipboard!");
  //     };

  //     return (
  //       <div
  //         style={{
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "space-between",
  //           gap: "10px",
  //           margin: "8px 0",
  //           borderBottom: "1px solid #ddd",
  //           paddingBottom: "5px",
  //         }}
  //       >
  //         {/* Left Side: File Icon and Name */}
  //         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
  //           {fileIcon}
  //           <div
  //             style={{
  //               cursor: "pointer",
  //               textDecoration: "none",
  //               color: "blue",
  //             }}
  //             onClick={() => {
  //               setSelectedFile(item);
  //               toggleModal();
  //             }}
  //           >
  //             {truncatedName}
  //           </div>
  //         </div>

  //         {/* Right Side: Copy Link */}
  //         <FaClipboard
  //           style={{ color: "#6c757d", cursor: "pointer" }}
  //           onClick={handleCopyLink}
  //           title="Copy Link"
  //         />
  //       </div>
  //     );
  //   }
  //   return null;
  // };
  const renderFileItem = (item) => {
    if (item?.file_path) {
      const fileName = item.file_path.split("/").pop();
      const fileExtension = fileName.split(".").pop().toLowerCase();

      const fileIcons = {
        pdf: <FaFilePdf style={{ color: "#d9534f" }} />,
        doc: <FaFileWord style={{ color: "#0275d8" }} />,
        docx: <FaFileWord style={{ color: "#0275d8" }} />,
        ppt: <FaFilePowerpoint style={{ color: "#f0ad4e" }} />,
        pptx: <FaFilePowerpoint style={{ color: "#f0ad4e" }} />,
        xls: <FaFileExcel style={{ color: "#5cb85c" }} />,
        xlsx: <FaFileExcel style={{ color: "#5cb85c" }} />,
        png: <FaFileImage style={{ color: "#5bc0de" }} />,
        jpg: <FaFileImage style={{ color: "#5bc0de" }} />,
        tiff: <FaFileImage style={{ color: "#5bc0de" }} />,
        mp4: <FaFileVideo style={{ color: "#ff6347" }} />,
        mp3: <FaFileAudio style={{ color: "#f0ad4e" }} />, // Added mp3 icon
      };

      const fileIcon = fileIcons[fileExtension] || <FaFile />;

      const truncatedName =
        fileName.length > 50
          ? `${fileName.substring(0, 50)}...${fileExtension}`
          : fileName;

      const handleCopyLink = () => {
        navigator.clipboard.writeText(item.file_path);
        alert("Link copied to clipboard!");
      };

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            margin: "8px 0",
            borderBottom: "1px solid #ddd",
            paddingBottom: "5px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {fileIcon}
            <div
              style={{
                cursor: "pointer",
                textDecoration: "none",
                color: "blue",
              }}
              onClick={() => {
                setSelectedFile(item);
                toggleModal();
              }}
            >
              {truncatedName}
            </div>
          </div>

          <FaClipboard
            style={{ color: "#6c757d", cursor: "pointer" }}
            onClick={handleCopyLink}
            title="Copy Link"
          />
        </div>
      );
    }
    return null;
  };

  if (!file) {
    return <h3>Please Wait Fetching Data...</h3>;
  }

  return (
    <Container className="mt-4">
      <Row className="mt-4">
        <Col sm="12" md="8">
          <Card className="file-card">
            <CardBody>
              {file.media_files?.find(
                (media) => media?.content_type?.toLowerCase() === "video"
              ) ? (
                <video
                  controls
                  width="100%"
                  src={
                    file.media_files.find(
                      (media) => media?.content_type?.toLowerCase() === "video"
                    )?.file_path
                  }
                />
              ) : (
                <p>Preview not available.</p>
              )}
              <CardTitle tag="h5" className="mt-3">
                {file.title}
              </CardTitle>
              <CardText className="mt-4">
                <p className="mt-2">
                  {file.created_at
                    ? new Date(file.created_at).toLocaleDateString()
                    : "No data available."}
                </p>
                <p className="description-container">{file.description}</p>
              </CardText>
            </CardBody>
          </Card>
        </Col>

        <Col sm="12" md="4">
          <Card className="transcription-box">
            <h5>Transcription</h5>
            <div>
              {transcription ? (
                <div dangerouslySetInnerHTML={{ __html: transcription }} />
              ) : (
                <p>No transcription available.</p>
              )}
            </div>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col sm="12" md="8">
          <Card className="p-2">
            <h5>Related Files</h5>
            <ul className="media-files-list ">
              {file.media_files.map((media, index) => (
                <li key={index} className="media-file-item">
                  {renderFileItem(media)}
                </li>
              ))}
            </ul>
          </Card>
        </Col>

        <Col sm="12" md="4">
          <Card className="last-updated-box p-2">
            <h5>Last Updated</h5>
            <p>
              {file.created_at
                ? new Date(file.created_at).toLocaleString()
                : "No data available."}
            </p>
          </Card>
        </Col>
      </Row>
      {/* <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>
          {selectedFile
            ? selectedFile.file_path.split("/").pop()
            : "File Preview"}
        </ModalHeader>
        <ModalBody>
          {selectedFile && (
            <div>
              {selectedFile.file_path.endsWith(".pdf") && (
                <embed
                  src={selectedFile.file_path}
                  width="100%"
                  height="600px"
                />
              )}
              {selectedFile.file_path.match(/\.(jpg|jpeg|png|tiff)$/) && (
                <img
                  src={selectedFile.file_path}
                  alt="Preview"
                  style={{ width: "100%", height: "auto" }}
                />
              )}
              {selectedFile.file_path.endsWith(".mp4") && (
                <video
                  controls
                  style={{ width: "100%" }}
                  src={selectedFile.file_path}
                />
              )}
              {selectedFile.file_path.endsWith(".docx") ||
              selectedFile.file_path.endsWith(".pptx") ? (
                <iframe
                  src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                    selectedFile.file_path
                  )}`}
                  width="100%"
                  height="600px"
                />
              ) : null}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal> */}
      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>
          {selectedFile
            ? selectedFile.file_path.split("/").pop()
            : "File Preview"}
        </ModalHeader>
        <ModalBody>
          {selectedFile && (
            <div>
              {selectedFile.file_path.match(
                /\.(jpg|jpeg|PNG|JPEG|png|TIFF|tiff)$/
              ) && (
                <img
                  src={selectedFile.file_path}
                  alt="Preview"
                  style={{ width: "100%", height: "auto" }}
                />
              )}
              {selectedFile.file_path.endsWith(".pdf") && (
                <embed
                  src={selectedFile.file_path}
                  width="100%"
                  height="600px"
                />
              )}
              {selectedFile.file_path.endsWith(".mp4") && (
                <video
                  controls
                  style={{ width: "100%" }}
                  src={selectedFile.file_path}
                />
              )}
              {selectedFile.file_path.endsWith(".docx") ||
              selectedFile.file_path.endsWith(".pptx") ||
              selectedFile.file_path.endsWith(".xlsx") ? (
                <iframe
                  src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                    selectedFile.file_path
                  )}`}
                  width="100%"
                  height="600px"
                />
              ) : null}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default KnowledgeDetail;
